<template>
  <Layout></Layout>
  <div class="container">
  <div class="row">
    <div class="container">
      <div class="col-md-12 app-title">
        <h1>Rats with [{{$route.params.query}}]</h1>
      </div>
      <div class="subtitle" id="ratCount" v-if="loaded">{{rats.display.length}} $RATs</div>
    </div>
    <div class="row">
      <div class="d-flex justify-content-center">
        <div class="btn-group btn-group-lg" role="group" aria-label="Navigation">
          <button type="button" class="btn btn-outline-primary" id="nav-showAll" @click="filterAll()">All</button>
          <button type="button" class="btn btn-outline-primary" id="nav-showSBCH" @click="filterSmart()">SmartBCH</button>
          <button type="button" class="btn btn-outline-primary" id="nav-showSLP" @click="filterSlp()">SLP</button>
        </div>
      </div>
    </div>
    <div class="row" v-if="rats.display.length == 0 && loaded">
      <p class="text-center">No $RATs found. </p>
    </div>
    <div class="row rats" v-if="rats.display.length > 0">
      <div v-for="rat in rats.display" :key="rat.number" class="col-lg-2 col-md-3 col-sm-6 text-center rat-card">
        <router-link v-bind:to="'/rat/id/' + rat.number">
          <img v-bind:src="'https://api.cryptor.at/original/rat/' + rat.number" class="img-fluid rat-image" /><br/>
          <span style='height:60px;display:block;' v-if="rat.price.toString() != 0">
            {{ rat.name }}<br/>{{ rat.price }} BCH
          </span>
          <span style='height:60px;display:block;' v-if="rat.price.toString() == '0'">
            {{ rat.name }}
          </span>
        </router-link>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Layout from "@/components/Layout";
export default {
  components: {Layout},
  data() {
    return {
      loaded: false,
      rats: {
        loaded: [],
        display: [],
      },
      metamaskEnabled: false,
    }
  },
  methods: {
    filterAll: function() {
      this.rats.display = this.rats.loaded;
    },
    filterSlp: function() {
      this.rats.display = this.rats.loaded.filter(function(rat) {
        return !rat.smart;
      });
    },
    filterSmart: function() {
      this.rats.display = this.rats.loaded.filter(function(rat) {
        return rat.smart;
      });
    },
  },
  mounted() {
    fetch(this.api_url + '/api/find/' + this.$route.params.query).then(function(response) {
      return response.json();
    }).then(result => {
      for(let i in result.rats) {
        this.$store.state.nftContract.tokenURI(result.rats[i].number).then(() => {
          result.rats[i].smart = true;
          this.rats.loaded.push(result.rats[i]);
          this.filterAll();
        }).catch(() => {
          result.rats[i].smart = false;
          this.rats.loaded.push(result.rats[i]);
          this.filterAll();
        });
      }
    });
  }
}
</script>